<template>
    <div class='stage-discrepancies'>
      <kendo-splitter class="stage-discrepancies-main" :panes="hSplitter1.panes" :orientation="hSplitter1.orientation" :scrollable="hSplitter1.scrollable">
          <kendo-splitter :panes="vSplitter1.panes" :orientation="vSplitter1.orientation" :scrollable="vSplitter1.scrollable">
            <SplitterContainer title="Decision (ID: 12345)" component="ActionsDataDiscrepancies"/>
            <SplitterContainer title="Annotations" component="Annotations"/>
          </kendo-splitter>
          <kendo-splitter :panes="vSplitter1.panes" :orientation="vSplitter1.orientation" :scrollable="vSplitter1.scrollable">
            <SplitterContainer title="Citations List with Data Discrepancies" component="CitationListDataDiscrepancies"/>
            <kendo-splitter :panes="hSplitter2.panes" :orientation="hSplitter2.orientation" :scrollable="hSplitter2.scrollable">
              <SplitterContainer title="Form 1" component="FormsDataDiscrepancies"/>
              <SplitterContainer title="Form 2" component="FormsDataDiscrepancies"/>
            </kendo-splitter>
          </kendo-splitter>
    </kendo-splitter>
    </div>
</template>

<script>
import SplitterContainer from '@/containers/SplitterContainer'

export default {
  name: 'data-discrepancies',
  components: {
    SplitterContainer
  },
  data () {
    return {
      isProcess: false,
      hSplitter1: {
        scrollable: true,
        orientation: 'horizontal',
        panes: [
          { collapsible: true, size: '20%' },
          { collapsible: true }
        ]
      },
      hSplitter2: {
        scrollable: true,
        orientation: 'horizontal',
        panes: [
          { collapsible: true, size: '50%' },
          { collapsible: true }
        ]
      },
      vSplitter1: {
        scrollable: true,
        orientation: 'vertical',
        panes: [
          { collapsible: true, size: '50%' },
          { collapsible: true, size: '50%' }
        ]
      }
    }
  }
}
</script>
